import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, PopoverController } from '@ionic/angular';
import * as _ from 'lodash';
import { FilterSerch } from 'src/app/models/filter-serch';
import { DataToolsService } from 'src/app/services/data-tools.service';

@Component({
  selector: 'app-popover-setings',
  templateUrl: './popover-setings.component.html',
  styleUrls: ['./popover-setings.component.scss'],
})
export class PopoverSetingsComponent implements OnInit {
  text: any = JSON.parse(localStorage.getItem('TEXTJSON'));
  pageContent: any = JSON.parse(sessionStorage.getItem("PAGE-CONTENT"));
  pages: any;
  dataUser: any = JSON.parse(localStorage.getItem('DATA-USER'));
  filter: FilterSerch = JSON.parse(sessionStorage.getItem("Filters"));
  constructor(
    private router: Router,
    private popCtrl: PopoverController,
    private alertController: AlertController,
    public dataToolsService: DataToolsService
  ) { }

  ngOnInit() {
    this.getItemPopoverSettings();
  }

 

  //Récupérer les page du popover 
  getItemPopoverSettings() {

    let popoversTemp = _.cloneDeep(this.text.popoverSettings);
    //Si user n'est pas connecté on affiche pas le logout
    if(!this.dataUser){
      popoversTemp = _.filter(popoversTemp, (item) => { return item.id == 2 || item.id == 3 || item.id == 4});

      // popoversTemp.push({
      //   label: 'Login',
      //   id: 5
      // });
      this.text.popoverSettings = popoversTemp;
      console.log("pop----", this.text.popoverSettings);
    }

    if(this.dataUser){
      this.text.popoverSettings = _.remove(popoversTemp, (item) => { return  item.id == 4});
      console.log("pop2----", this.text.popoverSettings);
      this.text.popoverSettings = popoversTemp;
    }
    
    let pages = _.filter(this.pageContent, function (o) { return o.CategoryName == "InfosPratiques"; })[0];

    //console.log("pages ==> ", pages);
    if (pages) this.pages = pages.Pages;
    let pageCgvTemp = _.filter(this.pageContent, function (o) { return o.CategoryName == "CGV"; })[0];
    //console.log("pageCgvTemp---", pageCgvTemp);
    let pageRgpdTemp = _.filter(this.pageContent, function (o) { return o.CategoryName == "COOKIES"; })[0];

    console.log("RGPD---", pageRgpdTemp);

    if(pageRgpdTemp){
      let pageRgpd = pageRgpdTemp.Pages[0];
      this.pages.push(pageRgpd);
      //console.log(pageRgpd)
    }
   
    if (pageCgvTemp) {
      let pageCgv = pageCgvTemp.Pages[0];
      this.pages.push(pageCgv);
    }

    //this.pages = _.remove(this.pages, (item) => {item.id == 2});
    console.log("this.pages ==> ", this.pages);
    
  }

  goToHome() {
    this.router.navigate(['home']);
    this.popCtrl.dismiss();
  }

  goToPageId(_id) {
    console.log('_id ==> ', _id);
    this.router.navigate(['informations/' + _id]);
    this.popCtrl.dismiss();
    
  }

  //Logout Fonction
  logout() {
    this.dataToolsService.logout();
  }



  onClickSetting(_popId) {
    if (_popId == 0) this.logout();
    if (_popId == 2) window.open("https://eurosatory.mybadgeonline.com/Pro", "_blank");
    if (_popId == 3) this.goToHome();
    if(_popId == 1) this.router.navigate(['account']);
    if(_popId == 4) this.router.navigate(['login']);
    this.popCtrl.dismiss()
  }
}
