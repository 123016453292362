import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { finalize, delay, tap } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
//import { ConfirmDialogService } from './confirm-dialog.service';


@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  count = 0;
  constructor(
    private spinner: NgxSpinnerService,
    public router: Router,
    //public dialog: ConfirmDialogService,
  ) { }

  // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //  this.spinner.show();
  //  return next.handle(req)
  //    .pipe(
  //      delay(1000),
  //      finalize(() => this.spinner.hide())
  //    )
  // }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log("req.url--->", req.url);
    if (req.url.toLowerCase().indexOf("connectuser") != -1 || req.url.toLowerCase().indexOf("generatelkpost") != -1) this.spinner.show();

    this.count++;
    return next.handle(req)
      //.pipe(tap(
      //  event => console.log(event),
      //  error => console.log(error),
      //),
      // catchError((error: any) => {
      //  // console.log("*******************************error", error)
      //   if (error.statusText == "Unauthorized") {
      //     localStorage.clear();
      //   }
      //   return of(error);
      // }),
      .pipe(tap(
        (event: any) => {

          if (event.type != 0) {
            //console.log(event)

            // if(event.url.indexOf("ConnectUser") != -1){
            //   this.spinner.show();
            //    return next.handle(req)
            //      .pipe(
            //        delay(1000),
            //        finalize(() => this.spinner.hide())
            //      )
            // }
          }
        },
        //error => console.log(error),
        (error: any) => {
          if (error.status !== 401 && error.status !== 999) {
            return;
          }
          //this.dialog.confirmThis("", "Votre session a expiré. Merci de vous re-connecter.", "alert", "", () => { localStorage.clear(); this.router.navigate(['login']); }, () => { });
        }
      ),
        finalize(() => {
          this.count--;
           if (this.count == 0) this.spinner.hide()
        })
      );
  }
}
