export class FilterSerch {
  categories = [];
  cluster = [];
  localisation = [];
  countries = [];
  categoriesConf = [];
  placesConf = [];
  themeId = null;
  isNewExhi = false;
  isLab = false;
  // constructor(categories, cluster, localisation, countries, categoriesConf){
  //     this.categories = categories;
  //     this.cluster = cluster;
  //     this.localisation = localisation;
  //     this.countries = countries;
  //     this.categoriesConf = categoriesConf;
  // }
}

