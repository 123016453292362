// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const api = "https://finderr.central-ws.com/"

// DEV
 const firebaseKey = 'BM9ovNHst1HgkbAxw_-48mlVoXfKAlawCjTKVIN3MsyMNXFX_3b7iMC6eUFAPUjGqBAnxlW7rbtF5VtWy5EUXD0';


export const environment = {
  production: false,
  defaultPortal: api + "Portals/0/",
  firebaseKey: firebaseKey,
  //DEV
  //  firebase: {
  //   apiKey: "AIzaSyCPFHPAQr_iPkiZTrUhOeIMmykl0ZuW-PM",
  //   authDomain: "esy2022-f0ace.firebaseapp.com",
  //   projectId: "esy2022-f0ace",
  //   storageBucket: "esy2022-f0ace.appspot.com",
  //   messagingSenderId: "743560405018",
  //   appId: "1:743560405018:web:d081fecbc4272ae9d371a3",
  //   measurementId: "G-4S4NE8FS0Q"

  //  },

  // PROD
  firebase: {
    apiKey: "AIzaSyCPFHPAQr_iPkiZTrUhOeIMmykl0ZuW-PM",
    authDomain: "esy2022-f0ace.firebaseapp.com",
    projectId: "esy2022-f0ace",
    storageBucket: "esy2022-f0ace.appspot.com",
    messagingSenderId: "743560405018",
    appId: "1:743560405018:web:f690320eba9cb561d371a3",
    measurementId: "G-JHZM45GG72"
  },

  jsonSecure: {
    getJson: "https://finderr-pwa.la-projets.com/api/HelperCtrl/GetJsonByName",
  },

  login: {
    userLogin: api + "DesktopModules/FinderrApi/API/Visitor/ConnectUser",
  },

  catalogue: {
    listeExposants: api + "DesktopModules/FinderrApi/API/Exhibitor/GetAllExhibitors",
    exposantDetail: api + "desktopModules/FinderrApi/API/Exhibitor/GetExhibitor/",
    generalInfos: api + "DesktopModules/FinderrApi/API/Info/GeneralInfo"
  },

  conference: {
    listEvenement: api + "/DesktopModules/FinderrApi/API/Event/GetAllEvents",
    evenementDetail: api + "/DesktopModules/FinderrApi/API/Event/GetEvent/"
  },

  favoris: {
    setFavori: api + 'DesktopModules/FinderrApi/API/Visitor/SetFavorite/',
    setAlert: api + 'DesktopModules/FinderrApi/API/Visitor/SetAlert/',
    sendMailFavs: api + 'DesktopModules/FinderrApi/API/Visitor/SendFavoritesByMail/'
  },

  linkedIn: {
    postExhibitor: api + 'DesktopModules/FinderrApi/API/Visitor/GenerateLKPost_Exhibitor'
  },

  tracking: {
    traceClick: api + 'DesktopModules/FinderrApi/API/Visitor/TraceClick',
    traceSearch: api + 'DesktopModules/FinderrApi/API/Visitor/TraceSearch',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
