import { Pipe, PipeTransform } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, RouterEvent, NavigationEnd } from '@angular/router';
import { FilterSerch } from '../models/filter-serch';
import * as _ from 'lodash';

@Pipe({
  name: 'globalSearch'
})
export class GlobalSearchPipe implements PipeTransform {
  constructor(
    private router: Router,
    private activ: ActivatedRoute,
  ) { }

  transform(list: any, args?: FilterSerch, isBookmarksMode?: boolean): any {
    
    console.log("list", list);
    if (!list) return null;

    args = (!args) ? JSON.parse(sessionStorage.getItem("Filters")) : args;
    console.log("args---", args);
    isBookmarksMode = (!isBookmarksMode) ? localStorage.getItem("isBookmarksMode") == "Yes" : isBookmarksMode;
    console.log("globalSearch:", list, args, isBookmarksMode);

    let pathName = this.router.url;
    //console.log(pathName);

    let user = JSON.parse(localStorage.getItem("DATA-USER"));
    console.log(user);

    let listOut = [];
    let listTemp = [];
    if (pathName.includes('exhibitors')) {
      //si le mode favoris est activé
      if (isBookmarksMode && user) {
        let ids = _.map(_.filter(user.Favorites, function (item) { return item.Type == "EXHIBITOR"; }), 'ID');
        //console.log(ids);

        listOut = _.filter(list, function (item) { return _.indexOf(ids, item.Exhi_Id) != -1 });
        console.log("listOut:", listOut);

        return listOut;
      }

      //si un thème est sélectionné
      if (args.themeId && args.themeId != -1) {
        listOut = _.filter(list, function (item) {
          if (!item.Themes) return false;
          return _.indexOf(item.Themes, args.themeId) != -1
        });
        console.log("listOut:", listOut);

        return listOut;
      }

      //S'il faut conserver uniquement les nouveaux exhi
      if (args.isNewExhi)
        list = _.filter(list, function (item) { return item.Exhi_IsNewExhibitor; });

      // S'il faut conserver uniquement les isLab
      if(args.isLab)
        list = _.filter(list, function (item) { return item.Exhi_IsLab; });
        
      //s'il y a une recherche en cours les filtres s'appliquent sinon la liste n'est pas modifiée.
      if (args.categories.length < 1
        && args.cluster.length < 1
        && args.localisation.length < 1
        && args.countries.length < 1
      ) {
        console.log("No global filter !");
        return list;
      }

      for (var i = 0; i < list.length; i++) {
        list[i]._halls = [];

        if (list[i].Stands) {
          for (var j = 0; j < list[i].Stands.length; j++) {
            let idToPush:any = this.getPlaceIdFromMapPoint(list[i].Stands[j].Hall);
            console.log("idToPush ==>", idToPush);

            if (idToPush) list[i]._halls.push(idToPush);
            //list[i]._halls.push(list[i].Stands[j].Hall);
          }
          console.log("LIST[i]----", list[i])
        }
       console.log("loc----",args.localisation);
       
        let isSearchOk = {
          cat: (args.categories.length < 1) ? true : this.intersectionOk(list[i].Categories, args.categories),
          clu: (args.cluster.length < 1) ? true : this.intersectionOk(list[i].Clusters, args.cluster),
          loc: (args.localisation.length < 1) ? true : this.intersectionOk(list[i]._halls, args.localisation),
          cou: (args.countries.length < 1) ? true : _.indexOf(args.countries, list[i].Exhi_FK_Country_Id) != -1
        }

        if (isSearchOk.cat && isSearchOk.clu && isSearchOk.cou && isSearchOk.loc) { listTemp.push(list[i]); console.log("listtttt", list)};
      }
      listOut = _.uniq(listTemp);
    }
    // else if (pathName.includes('solutions') || pathName.includes('product')) {
    //   //si le mode favoris est activé
    //   if (isBookmarksMode && user) {
    //     let ids = _.map(_.filter(user.Favorites, function (item) { return item.Type == "PRODUCT"; }), 'ID');
    //     //console.log(ids);

    //     listOut = _.filter(list, function (item) { return _.indexOf(ids, item.Prod_Id) != -1 });
    //     console.log("listOut:", listOut);

    //     return listOut;
    //   }

    //   //s'il y a une recherche en cours les filtres s'appliquent sinon la liste n'est pas modifiée.
    //   if (args.categories.length < 1
    //     && args.cluster.length < 1
    //     && args.localisation.length < 1
    //     && args.countries.length < 1
    //   ) {
    //     console.log("No global filter !");
    //     return list;
    //   }

    //   for (var i = 0; i < list.length; i++) {
    //     list[i]._halls = [];

    //     if (list[i].Stands) {
    //       for (var j = 0; j < list[i].Stands.length; j++) {
    //         list[i]._halls.push(list[i].Stands[j].Hall);
    //       }
    //     }

    //     if (this.intersectionOk(list[i]._halls, args.localisation)) {
    //       listOut.push(list[i]);
    //     }
    //   }
    // }
    else if (pathName.includes('conference')) {
      //si le mode favoris est activé
      if (isBookmarksMode && user) {
        let ids = _.map(_.filter(user.Favorites, function (item) { return item.Type == "EVENT"; }), 'ID');
        //console.log(ids);

        listOut = _.filter(list, function (item) { return _.indexOf(ids, item.Event_Id) != -1 });
        console.log("listOut:", listOut);

        return listOut;
      }

      //s'il y a une recherche en cours les filtres s'appliquent sinon la liste n'est pas modifiée.
      if (args.categoriesConf.length < 1
        && args.placesConf.length < 1
      ) {
        console.log("No global filter !");
        return list;
      }

      for (var i = 0; i < list.length; i++) {
        let isSearchOk = {
          cat: (args.categoriesConf.length < 1) ? true : this.intersectionOk(list[i].Categories, args.categoriesConf),
          pla: (args.placesConf.length < 1) ? true : _.indexOf(args.placesConf, list[i].Place.Place_Id) != -1
        }

        if (isSearchOk.cat && isSearchOk.pla) { listTemp.push(list[i]); }
      }
      listOut = _.uniq(listTemp);
    }
    else if (pathName.includes('speaker')) {
      //si le mode favoris est activé
      if (isBookmarksMode && user) {
        let ids = _.map(_.filter(user.Favorites, function (item) { return item.Type == "SPEAKER"; }), 'ID');
        //console.log(ids);

        listOut = _.filter(list, function (item) { return _.indexOf(ids, item.User_Id) != -1 });
        console.log("listOut:", listOut);

        return listOut;
      }

      listOut = list;
    }
    else if (pathName.includes('programme')) {
      if (isBookmarksMode && user) {
        let ids = _.map(_.filter(user.Favorites, function (item) { return item.Type == "PROGRAM"; }), 'ID');
        // console.log(ids);

        listOut = _.filter(list, function (item) {
          for (let i = 0; i < item.ProgramItems.length; i++) {
            return _.indexOf(ids, JSON.parse(item.ProgramItems[i].ItemID)) != -1
          }
        });
        console.log("listOut:", listOut);
        return listOut;
      }

      listOut = list;
    }
    else {
      console.log("pathName:", pathName);
      return list;
    }

    console.log("listOut:", listOut);
    return listOut;
  }

  intersectionOk(listA: any, listB: any) {
    let listAstr = (!listA) ? [] : listA.map(String);
    let listBstr = (!listB) ? [] : listB.map(String);

    let result = _.intersection(listAstr, listBstr);
  //  console.log(result.length);

    return result.length > 0;
  }

  //Retourner PlaceId à partir du mapPoint
  getPlaceIdFromMapPoint(_mapPoint){
    let idToReturn:any;
    let objToReturn:any;
    let listGenerale = JSON.parse(sessionStorage.getItem("GENERAL-INFOS"));
    let listPlacesLocal:any = [];
    if(listGenerale) listPlacesLocal = listGenerale.Places;
    if(listPlacesLocal) objToReturn = _.filter(listPlacesLocal, function(item) {return item.MapPoint == _mapPoint})[0];
    idToReturn = (objToReturn) ? objToReturn.Place_Id : null;
    return idToReturn;
  }
}
