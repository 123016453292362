import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { CookieService } from 'ngx-cookie-service';
import { DataToolsService } from 'src/app/services/data-tools.service';


@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {

  text: any = JSON.parse(localStorage.getItem('TEXTJSON'));
  show:boolean = false;
  cookie: any;
  langue = localStorage.getItem('langue');
  constructor(
    private modalController: ModalController,
    private route: Router,
    private alertController: AlertController,
    public dataToolsService: DataToolsService,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    this.cookie = this.cookieService.get('accept-cookies');
     console.log("THIS-TEXT----", this.text)
     
     setTimeout(()=>{
       this.show = true}, 5000);
  }

  ionViewWillEnter() {
    this.text = JSON.parse(localStorage.getItem('TEXTJSON'));
  }



  //Création du cookie pour la publicité
   //expiration Cookie
   cookiesExp(){
     //console.log("je suis le cookie");
    const dateNow = new Date();
    dateNow.setHours(dateNow.getHours() + 24);
    this.cookieService.set('isLoggedIn', 'true', dateNow);
  }
  
   /**
   * Arrête la modal
   */
    dismissModal(){
      this.cookiesExp();
      this.modalController.dismiss();
      console.log("Cookie---", this.cookie);
      if(!this.cookie){
        this.dataToolsService.presentAlertCookies();
      }

      // let cookieAlert = this.cookieService.get('accept-cookies');
      // if(!cookieAlert && cookie){
      //  this.dataToolsService.presentAlertCookies();
      // } 
      
    }

  
  

    /**
     * Naviger vers la page sommaire
     */
    // goToSommaire(){
    //   this.route.navigate(['home']);
    //   this.dismissModal();
    // }

}
