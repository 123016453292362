import { Injectable } from '@angular/core';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
@Injectable({
  providedIn: 'root'
})
export class TopicService {

  fcmServer = localStorage.getItem('TOKEN-NOT');
  //serverkey = "AAAA36NNWQI:APA91bF0VBIYTVf6YlOCqsLtBQ_TV6dHK0wA7HwETTDYmVgUHAzZZ768OWM6SMO6pHj4ytTgT9fTJrI9sbKFHRgKzXguqfoTD6e51fFspDtRGlhJo1HFGX6ft8WvR5XDq-TsGw9v1_HI"
  serverkey = "AAAArR-nYBo:APA91bG5-EV48F7N5Q2KNn6sGgOf0TBVlLFuTi0XIGV4Lpf_BqqgT47X4yx7nJb75XWwFstAN-4b9c4WUeUZ6yihXKxTdM3WdRuNHgFqpUeMelCXGz7g0YEfRTVbUYkLrKR5ujwOmwCp"
  constructor() { }

  subscribeTokenToTopic(token, topic) {
    fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`, {
      method: 'POST',
      headers: new Headers({
        //Authorization: this.serverkey
        Authorization: `key=${this.serverkey}`
      })
    })
      .then((response) => {
        if (response.status < 200 || response.status >= 400) {
          console.log(response.status, response);
        }
        console.log(`"${topic}" is subscribed`);
      })
      .catch((error) => {
        console.error(error.result);
      });
    return true;
  }

  unsubscribeTokenToTopic(token, topic){
  
    fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`, {
     method: 'DELETE',
    
     headers: new Headers({
     // Content-Type: application/json
      Authorization: `key=${this.serverkey}`
     })
     
    }
    
    )
    .then((response) => {
      if (response.status < 200 || response.status >= 400) {
        console.log(response.status, response);
      }
      console.log(`"${topic}" is unsubscribed`);
    })
    .catch((error) => {
      console.error(error.result);
    });
  return true;
  }
}

