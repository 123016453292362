import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'map-view',
    loadChildren: () => import('./map-view/map-view.module').then( m => m.MapViewPageModule)
  },
  {
    path: 'sommaire',
    loadChildren: () => import('./sommaire/sommaire.module').then( m => m.SommairePageModule)
  },
  {
    path: 'exhibitors',
    loadChildren: () => import('./exhibitors/exhibitors.module').then( m => m.ExhibitorsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'detail-exhibitors/:exhiId',
    loadChildren: () => import('./detail-exhibitors/detail-exhibitors.module').then( m => m.DetailExhibitorsPageModule)
  },
  {
    path: 'themes',
    loadChildren: () => import('./themes/themes.module').then( m => m.ThemesPageModule)
  },
  {
    path: 'conferences',
    loadChildren: () => import('./conferences/conferences.module').then( m => m.ConferencesPageModule)
  },
  {
    path: 'speakers',
    loadChildren: () => import('./speakers/speakers.module').then( m => m.SpeakersPageModule)
  },
  {
    path: 'programme',
    loadChildren: () => import('./programme/programme.module').then( m => m.ProgrammePageModule)
  },
  {
    path: 'solutions',
    loadChildren: () => import('./solutions/solutions.module').then( m => m.SolutionsPageModule)
  },
  {
    path: 'product-detail/:prodId',
    loadChildren: () => import('./product-detail/product-detail.module').then( m => m.ProductDetailPageModule)
  },
  {
    path: 'speaker-detail/:usrId',
    loadChildren: () => import('./speaker-detail/speaker-detail.module').then( m => m.SpeakerDetailPageModule)
  },
  {
    path: 'detail-conference/:evtId',
    loadChildren: () => import('./detail-conference/detail-conference.module').then( m => m.DetailConferencePageModule)
  },
  {
    path: 'restaurants',
    loadChildren: () => import('./restaurants/restaurants.module').then( m => m.RestaurantsPageModule)
  },
  {
    path: 'informations/:pageId',
    loadChildren: () => import('./informations/informations.module').then( m => m.InformationsPageModule)
  },
  {
    path: 'do-business',
    loadChildren: () => import('./do-business/do-business.module').then( m => m.DoBusinessPageModule)
  },
  {
    path: 'get-daily',
    loadChildren: () => import('./get-daily/get-daily.module').then( m => m.GetDailyPageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then( m => m.AccountPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
