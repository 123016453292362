import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {

  pageContent: any = JSON.parse(sessionStorage.getItem("PAGE-CONTENT"));
  pages: any;
  countVersion: number = Math.random();
  
  constructor(
    private router: Router,
    private popoverController: PopoverController,
    private spinner: NgxSpinnerService,
    
  ) { }

  ngOnInit() {
    this.getItemPopover();
  }

  //Récupérer les page du popover 
  getItemPopover(){
   // console.log("pageContent///////", this.pageContent);
    this.pages = _.filter(this.pageContent, function(o) { return o.CategoryName == "Services"; })[0].Pages;
    //console.log("item------->", this.pages);
  }

  goTo(title, stands){
    console.log('title--------', title);
    if (title == "Restauration" || title.includes("Food")) this.router.navigate(['restaurants']);
    if(title != "Restauration" && !title.includes("Food")){
      this.spinner.show();
      setTimeout(() => {
        this.router.navigate(['map-view'], { queryParams: { myArrayMatPoints: JSON.stringify(stands), version: this.countVersion} });
        
        this.spinner.hide();
      }, 1200);
    }
    this.popoverController.dismiss();
  }
}
